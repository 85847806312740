import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApartments } from '../store/apartmentsSlice';

const formatApartmentNo = (apartmentNo) => {
    return parseInt(apartmentNo, 10) < 10 ? `0${apartmentNo}` : apartmentNo;
};

const getApartmentFloorAndNo = (apartment) => {
    return `${apartment.block}-${apartment.floor}.${formatApartmentNo(apartment.no)}`;
};

const sortApartmentAsFloor = (a, b) => {
    const floorA = a.floor === '12A' ? 13 : parseInt(a.floor);
    const floorB = b.floor === '12A' ? 13 : parseInt(b.floor);
    return floorA - floorB;
};

const SQUARE_TYPES = {
    small: { value: 1, label: "Studio + Suite 1Pn (3 người)" },
    medium: { value: 2, label: "Premium 1Pn+(4 người)" },
    large: { value: 3, label: "Luxury 2Pn+(6 người)" },
    ultra_large: { value: 4, label: "President 3Pn (8 người)" },
};

const sortApartmentAsSquareType = (a, b) => {
    return SQUARE_TYPES[a.square_type].value - SQUARE_TYPES[b.square_type].value;
};


const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

function Home() {
    const dispatch = useDispatch();
    const { data: apartments, loading, error, lastFetched } = useSelector(state => state.apartments);
    const [sortedApartments, setSortedApartments] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentSort, setCurrentSort] = useState('square'); // 'floor' or 'square'

    useEffect(() => {
        const shouldFetch = !lastFetched || (Date.now() - lastFetched > CACHE_DURATION);

        if (shouldFetch && apartments.length === 0) {
            dispatch(fetchApartments());
        }
    }, [dispatch, lastFetched, apartments.length]);

    useEffect(() => {
        setSortedApartments([...apartments]);
    }, [apartments]);

    const handleSort = (type) => {
        setCurrentSort(type);
        let sorted = [...apartments];
        if (type === 'floor') {
            sorted.sort(sortApartmentAsFloor);
        } else {
            sorted.sort(sortApartmentAsSquareType);
        }
        setSortedApartments(sorted);
        setIsMenuOpen(false);
    };

    const renderApartmentGrid = () => {
        if (currentSort === 'square') {
            return Object.entries(SQUARE_TYPES).map(([type, { label }]) => {
                const typeApartments = sortedApartments.filter(apt => apt.square_type === type);
                if (typeApartments.length === 0) return null;

                return (
                    <div key={type} className="category-section">
                        <h2 className="category-title">{label}</h2>
                        <div className="apartment-grid">
                            {typeApartments.map((item) => (
                                <Link
                                    key={item._id}
                                    to={`/${item.floor}-${item.no}`}
                                    className="apartment-item"
                                >
                                    {getApartmentFloorAndNo(item)}
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            });
        }

        return (
            <div className="apartment-grid">
                {sortedApartments.map((item) => (
                    <Link
                        key={item._id}
                        to={`/${item.floor}-${item.no}`}
                        className="apartment-item"
                    >
                        {getApartmentFloorAndNo(item)}
                    </Link>
                ))}
            </div>
        );
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="home-container">
            <div className="header">
                <h1 className="title">Hình căn hộ</h1>
                <div className="menu-container">
                    <button
                        className="menu-button"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        ☰
                    </button>
                    {isMenuOpen && (
                        <div className="menu-dropdown">
                            <button onClick={() => handleSort('floor')}>
                                Sắp xếp theo tầng
                            </button>
                            <button onClick={() => handleSort('square')}>
                                Sắp xếp theo loại căn
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {renderApartmentGrid()}

            <style jsx>{`
                .home-container {
                    padding: 20px;
                    max-width: 1200px;
                    margin: 0 auto;
                }

                .category-section {
                    margin-bottom: 40px;
                }

                .category-title {
                    font-size: 1.2em;
                    color: #666;
                    margin: 20px 0;
                    padding-bottom: 10px;
                    border-bottom: 2px solid #eee;
                }

                .header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-bottom: 40px;
                }

                .title {
                    text-align: center;
                    margin: 0;
                }

                .menu-container {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .menu-button {
                    background: none;
                    border: none;
                    font-size: 24px;
                    cursor: pointer;
                    padding: 10px;
                    color: #333;
                }

                .menu-dropdown {
                    position: absolute;
                    right: 0;
                    top: 100%;
                    background: white;
                    border: 1px solid #eee;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                    z-index: 1000;
                    min-width: 200px;
                }

                .menu-dropdown button {
                    display: block;
                    width: 100%;
                    padding: 12px 20px;
                    text-align: left;
                    border: none;
                    background: none;
                    cursor: pointer;
                    color: #333;
                    font-size: 1em;
                }

                .menu-dropdown button:hover {
                    background: #f8f8f8;
                }

                .menu-dropdown button:not(:last-child) {
                    border-bottom: 1px solid #eee;
                }

                .apartment-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                    gap: 20px;
                    padding: 20px;
                }

                .apartment-item {
                    background: #f8f8f8;
                    border: 1px solid #eee;
                    border-radius: 8px;
                    padding: 20px;
                    text-align: center;
                    text-decoration: none;
                    color: #333;
                    font-size: 1.2em;
                    transition: all 0.3s ease;
                }

                .apartment-item:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                    background: #fff;
                }

                .loading, .error {
                    text-align: center;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                }

                .error {
                    color: red;
                }

                @media (max-width: 768px) {
                    .category-title {
                        font-size: 1.1em;
                        margin: 15px 0;
                    }

                    .apartment-grid {
                        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                        gap: 15px;
                        padding: 15px;
                    }

                    .apartment-item {
                        padding: 15px;
                        font-size: 1.1em;
                    }
                }
            `}</style>
        </div>
    );
}

export default Home; 