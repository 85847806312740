import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchApartments = createAsyncThunk(
    'apartments/fetchApartments',
    async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/apartments/available/short`);
        const result = await response.json();
        return result.data;
    }
);

const apartmentsSlice = createSlice({
    name: 'apartments',
    initialState: {
        data: [],
        loading: false,
        error: null,
        lastFetched: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApartments.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchApartments.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.lastFetched = Date.now();
            })
            .addCase(fetchApartments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default apartmentsSlice.reducer; 