import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Gallery from './components/Gallery';
import Home from './components/Home';

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:apartmentId" element={<Gallery />} />
                </Routes>
            </Router>
        </Provider>
    );
}

export default App; 